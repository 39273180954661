import type {
  RumInitConfiguration,
  RumResourceEvent,
} from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';
import type { AnalyticsSource } from '../../types';

export interface DataDogConfig extends RumInitConfiguration {
  enableSessionReplay?: boolean;
  addAction?: (name: string) => void;
  isInitialized?: () => boolean;
}

export const isDatadogSyntheticTest = (): boolean =>
  typeof window !== 'undefined' &&
  navigator.userAgent.includes('DatadogSynthetics');

export default {
  initialize: ({
    applicationId,
    clientToken,
    service,
    env,
    enableSessionReplay,
  }) => {
    datadogRum.init({
      applicationId,
      clientToken,
      service,
      env,
      site: 'datadoghq.com',
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      // remove token from view & resource urls before sending to DataDog
      beforeSend: (event) => {
        event.view.url = event.view.url.replace(
          /token=[^&]*/,
          'token=REDACTED',
        );
        if (event.resource) {
          // Casting event to RumResourceEvent type otherwise .resource is of type unknown
          (event as RumResourceEvent).resource.url = (
            event as RumResourceEvent
          ).resource.url.replace(/token=[^&]*/, 'token=REDACTED');
        }
      },
    });

    if (enableSessionReplay) {
      datadogRum.startSessionReplayRecording();
    }
  },
  addAction: (name: string) => {
    datadogRum.addAction(name);
  },
  isInitialized: () => Boolean(datadogRum.getInitConfiguration()),
} as AnalyticsSource<DataDogConfig>;
