import amplitude, { type AmplitudeProps } from './sources/amplitude';
import datadog, { type DataDogConfig } from './sources/datadog';
import experiment from './sources/experiment';
import gtm, { type GoogleTagManagerConfig } from './sources/gtm';
import segment, { type SegmentProps } from './sources/segment';
import sentry, { type SentryConfig } from './sources/sentry';
import whoopUserEventTracker from './sources/whoop-analytics';
import type {
  AnalyticsContext,
  UserProfile,
  InitializeFunction,
  IdentifyFunction,
  EventFunction,
  AnalyticsSource,
  WhoopAnalytics,
  UserEventTrackerProps,
  RouteChangeTrackerProps,
  ItemContext,
  PurchaseContext,
} from './types';

interface WebAnalyticsProps {
  amplitude?: AmplitudeProps;
  datadog?: DataDogConfig;
  gtm?: GoogleTagManagerConfig;
  segment?: SegmentProps;
  sentry?: SentryConfig;
}

export default {
  initialize: ({
    amplitude: amplitudeProps,
    datadog: datadogProps,
    gtm: gtmProps,
    segment: segmentProps,
    sentry: sentryProps,
  }) => {
    if (amplitudeProps) {
      try {
        amplitude.initialize(amplitudeProps);
      } catch (error) {
        throw new Error('Failed to initialize Amplitude');
      }
    }

    if (datadogProps) {
      if (!datadog.isInitialized()) {
        try {
          datadog.initialize(datadogProps);
        } catch (error) {
          throw new Error('Failed to initialize DataDog RUM');
        }
      }
    }

    if (gtmProps) {
      try {
        gtm.initialize(gtmProps);
      } catch (error) {
        throw new Error('Failed to initialize GTM');
      }
    }

    if (segmentProps) {
      try {
        segment.initialize(segmentProps);
      } catch (error) {
        throw new Error('Failed to initialize Segment');
      }
    }

    if (sentryProps) {
      try {
        sentry.initialize(sentryProps);
      } catch (error) {
        throw new Error('Failed to initialize Sentry');
      }
    }
  },
  identify: (user) => {
    if (segment.identify) {
      segment.identify(user);
    }
  },
  trackAmplitudeEvent: (eventName, context) => {
    if (amplitude.trackEvent) {
      amplitude.trackEvent(eventName, context);
    }
  },
  setAmplitudeUserId: (userId) => {
    if (amplitude.setUserId) {
      amplitude.setUserId(userId);
    }
  },
  setAmplitudeUserProperty: (key, value) => {
    if (amplitude.setUserProperty) {
      amplitude.setUserProperty(key, value);
    }
  },
  unsetAmplitudeUserProperty: (key) => {
    if (amplitude.unsetUserProperty) {
      amplitude.unsetUserProperty(key);
    }
  },
  trackError: (err) => {
    if (sentry.trackError) {
      sentry.trackError(err);
    }
  },
  trackSegmentEvent: (eventName, context) => {
    if (segment.trackEvent) {
      segment.trackEvent(eventName, context);
    }
  },
  getVariant: (experimentName, filters) =>
    experiment.getVariant(experimentName, filters),
  getVariantByUserToken: (experimentName, userToken, filters) =>
    experiment.getVariantByUserToken(experimentName, userToken, filters),
  registerUserEventTracker: (props: UserEventTrackerProps) => {
    whoopUserEventTracker.registerUserEventTracker(props);
  },
  notifyRouteChange: (props: RouteChangeTrackerProps) => {
    whoopUserEventTracker.notifyRouteChange(props);
  },
  trackPageView: (context) => {
    gtm.trackPageView(context);
  },
  trackPurchase: (context) => {
    gtm.trackPurchase(context);
  },
  trackGoogleEvent: (eventName, context) => {
    gtm.trackGoogleEvent(eventName, context);
  },
  getDeviceId: () => {
    return amplitude?.getDeviceId?.();
  },
  getSessionId: () => {
    return amplitude?.getSessionId?.();
  },
  trackDatadogAction: (name) => {
    datadog.addAction(name);
  },
} as WhoopAnalytics<WebAnalyticsProps>;

// Type Exports
export type {
  AnalyticsContext,
  UserProfile,
  InitializeFunction,
  IdentifyFunction,
  EventFunction,
  AnalyticsSource,
  WhoopAnalytics,
  WebAnalyticsProps,
  SegmentProps,
  DataDogConfig,
  SentryConfig,
  AmplitudeProps,
  GoogleTagManagerConfig,
  ItemContext,
  PurchaseContext,
};
