/* eslint-disable id-length */
import type { Currency, Language, Region } from '../types/internationalization';
import { isRestOfWorld } from './i18nHelpers';

export interface PriceFormatOptions {
  /** Viewer country – used for format locale if `language` is also present */
  country?: string;
  /** Viewer language – used for format locale (in addition to `country` if specified) */
  language?: Language;
  /** Is the price in lowest currency denominator? */
  inCents?: boolean;
  /** If cents should not always be shown and the price has decimals – should the price be rounded up? */
  roundUp?: boolean;
  /*** If cents should not always be shown and the price has decimals - should the price be rounded down */
  roundDown?: boolean;
  /** Should cents always be shown if the price does not have decimals? `true` would return `$100.00`, `false` would return `$100` */
  showCents?: boolean;
}

export interface SiteFormatValues {
  currency: Currency;
  language: Language;
  region: Region;
}

export const formatDisplayPrice = (
  price: number,
  currency: Currency,
  formatOptions?: PriceFormatOptions,
): string => {
  return formatPrice(price, currency, {
    ...formatOptions,
    showCents: false,
  });
};

export const formatCartPrice = (
  price: number,
  currency: Currency,
  formatOptions?: PriceFormatOptions,
): string => {
  return formatPrice(price, currency, {
    ...formatOptions,
    showCents: true,
    roundUp: false,
    roundDown: false,
  });
};

export const formatPriceWithSiteValues = (
  price: number,
  siteValues: SiteFormatValues,
  formatOptions?: PriceFormatOptions,
): string => {
  const { currency, language, region } = siteValues;
  const country = isRestOfWorld(currency, region) ? region : undefined;
  return formatPrice(price, currency, { country, language, ...formatOptions });
};

const generateFormattingLocale = (
  currency: Currency,
  language?: Language,
  country?: string,
): string | undefined => {
  if (country === 'MX') {
    // We need to use es-MX for expressing dollars as USD and not $ ($ is also used for Mexican pesos) [GRO-4173] - https://whoopinc.atlassian.net/browse/GRO-4173
    return `es-MX`;
  }

  const countryPriceFormatOverrides = {
    es: { default: 'US', eur: 'ES' },
    it: { default: 'IT' },
    pt: { default: 'PT' },
  };
  // forcedFormattingCountry is built to achieve ideal price formatting for the new latin langs (es, it, pt)
  // Locale here is not representative of users actual locale. (Example: pt-EU (€ 12) becomes pt-PT (12 €))
  let locale = country && language ? `${language}-${country}` : language;
  if (['es', 'it', 'pt'].includes(language ?? '')) {
    const forcedFormattingCountry =
      countryPriceFormatOverrides[language ?? ''][currency] ??
      countryPriceFormatOverrides[language ?? ''].default;
    locale = `${language}-${forcedFormattingCountry}`;
  }
  return locale;
};

export const formatPrice = (
  price: number,
  currency: Currency,
  formatOptions?: PriceFormatOptions,
): string => {
  const {
    country,
    language,
    inCents = true,
    roundUp = false,
    roundDown = false,
    showCents,
  } = formatOptions ?? {};

  let priceInDollars = inCents ? Math.round(price) / 100 : price;
  if (!showCents && roundUp) {
    priceInDollars = Math.ceil(priceInDollars);
  } else if (!showCents && roundDown) {
    priceInDollars = Math.floor(priceInDollars);
  }

  const fractionDigits = showCents || priceInDollars % 1 !== 0 ? 2 : 0;
  const locale = generateFormattingLocale(currency, language, country);

  return Intl.NumberFormat(locale, {
    currency,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    style: 'currency',
  }).format(priceInDollars);
};

export const splitPrice = ({
  country,
  currency,
  formattedPrice,
  language,
}: {
  country?: string;
  currency: Currency;
  formattedPrice: string;
  language: Language;
}): { dollarAmount: string; centsAmount: string; decimalSeparator: string } => {
  const locale = generateFormattingLocale(currency, language, country);
  const decimalSeparator =
    Intl.NumberFormat(locale, { currency, style: 'currency' })
      .formatToParts(1.1)
      .find((part) => part.type === 'decimal')?.value || '.';
  return {
    dollarAmount: formattedPrice.split(decimalSeparator)[0],
    centsAmount: formattedPrice.split(decimalSeparator)[1],
    decimalSeparator,
  };
};

/**
 * Takes a numeric amount, applies the given exchange rate, rounds to the provided nearest value, formats according to provided currency (taking into consideration language)
 * @param totalAmount (ex. 10000 = $100 USD)
 * @param exchangeRate (ex. 83, 1 USD = 83 INR)
 * @param exchangeCurrency (ex. INR)
 * @param exchangeRoundingNearest (ex. 50000 (500 INR), such that 12005 rounds to 12500)
 * @param language
 * @returns formatted price in the provided currency (ex. ₹8,300)
 */
export const formatExchangeCurrencyAmount = (
  totalAmount: number,
  exchangeRate: number,
  exchangeCurrency: string,
  exchangeRoundingNearest: number,
  language: string,
): string => {
  const estimatedTotalAmount = totalAmount * exchangeRate; //ex. 2216100 INR
  const roundedTotalAmount =
    Math.ceil(estimatedTotalAmount / exchangeRoundingNearest) *
    exchangeRoundingNearest; //ex. 2250000 INR

  return Intl.NumberFormat(language, {
    currency: exchangeCurrency,
    style: 'currency',
    maximumFractionDigits: exchangeRoundingNearest ? 0 : 2,
  }).format(roundedTotalAmount / 100);
};
