/* eslint-disable @typescript-eslint/no-explicit-any */
import * as amplitude from '@amplitude/analytics-browser';
import type { AnalyticsSource } from '../../types';
import { isDatadogSyntheticTest } from '../datadog';

declare global {
  interface Window {
    analytics: any;
  }
}

export interface AmplitudeProps {
  key: string;
  options: Record<string, unknown>;
}

const shouldTrackAmplitudeEvent = (): boolean =>
  typeof window !== 'undefined' &&
  (window as any)?.ENV !== 'local' &&
  !isDatadogSyntheticTest();

export default {
  initialize: (props) => {
    amplitude.init(props.key, undefined, props.options);
  },
  trackEvent: (eventName, context) => {
    if (!shouldTrackAmplitudeEvent()) return;
    amplitude.track(eventName, context);
  },
  setUserProperty: (key, value) => {
    if (!shouldTrackAmplitudeEvent()) return;
    const event = new amplitude.Identify();
    event.set(key, value);
    amplitude.identify(event);
  },
  setUserId: (userId) => {
    if (!shouldTrackAmplitudeEvent()) return;
    amplitude.setUserId(userId.toString());
  },
  unsetUserProperty: (key) => {
    if (!shouldTrackAmplitudeEvent()) return;
    const event = new amplitude.Identify();
    event.unset(key);
    amplitude.identify(event);
  },
  getDeviceId: () => {
    return amplitude.getDeviceId();
  },
  getSessionId: () => {
    return amplitude.getSessionId();
  },
} as AnalyticsSource<AmplitudeProps>;
