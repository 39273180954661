/* eslint-disable @typescript-eslint/no-explicit-any */

export interface AnalyticsContext {
  sku?: string;
  price?: number;
  membership_quantity?: number;
  [attr: string]:
    | number
    | string
    | boolean
    | null
    | undefined
    | AnalyticsContext
    | PurchaseContext;
}

export interface UserProfile {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface PageViewContext {
  page_location: string;
  language: string;
  page_title: string;
}

export type ImpactCategories = '12-month' | '24-month' | '';

export interface ItemContext {
  item_id: string;
  item_name: string;
  item_variant?: string;
  price: number;
  quantity: number;
  category: ImpactCategories;
}

export interface PurchaseContext {
  currency: string;
  transaction_id: string;
  value: number;
  coupon?: string;
  shipping: number;
  tax: number;
  items: ItemContext[];
  variables?: Record<string, any>; // Pass any type of variable to GTM
}

export type InitializeFunction<T> = (properties: T) => void;
export type EventFunction = (
  eventName: string,
  context?: AnalyticsContext,
) => void;
export type UserEventFunction = (
  eventName: string,
  context?: AnalyticsContext,
  trigger?: { type: string },
) => void;
export type IdentifyFunction = (user: UserProfile) => void;
export type ErrorFunction = (err: any) => void;
export type GetExperimentVariantFunction = (
  name: string,
  experimentFilters?: AnalyticsContext,
) => Promise<string>;
export type PageViewFunction = (context: PageViewContext) => void;
export type PurchaseFunction = (context: PurchaseContext) => void;

export interface AnalyticsSource<T> {
  isInitialized: () => boolean;
  addAction: (name: string) => unknown;
  initialize: InitializeFunction<T>;
  trackEvent?: EventFunction;
  trackError?: ErrorFunction;
  identify?: IdentifyFunction;
  setUserId?: (userId: number) => void;
  setUserProperty?: (key: string, value: string) => void;
  unsetUserProperty?: (key: string) => void;
  getDeviceId?: () => string | null | undefined;
  getSessionId?: () => string | null | undefined;
}

export interface ExperimentServiceVariantServer {
  name: string;
  filters?: AnalyticsContext;
  experimentCookie?: string;
}

/**
 * TODO infer types from objects.
 */
export interface ExperimentSource {
  getVariant: GetExperimentVariantFunction;
  getVariantByUserToken: (
    name: string,
    userToken: string,
    experimentFilters?: AnalyticsContext,
  ) => Promise<string | null>;
  getVariantOnServer: (
    props: ExperimentServiceVariantServer,
  ) => Promise<{ variant: string | null; uuid: string }>;
}

export interface GoogleSource<T> {
  initialize: InitializeFunction<T>;
  trackPageView: PageViewFunction;
  trackPurchase: PurchaseFunction;
  trackGoogleEvent: EventFunction;
}

export interface UserEventTrackerProps {
  callbackFn: UserEventFunction;
  trackClicks?: boolean;
  trackScroll?: boolean;
}

export interface RouteChangeTrackerOptions {
  root: Element | Document | null;
  rootMargin: string;
  threshold: number;
}

export interface RouteChangeTrackerProps {
  eventName: string;
  options?: RouteChangeTrackerOptions;
}

export interface WhoopAnalytics<T>
  extends AnalyticsSource<T>,
    ExperimentSource,
    GoogleSource<T> {
  trackAmplitudeEvent: EventFunction;
  setAmplitudeUserId: (userId: number) => void;
  setAmplitudeUserProperty: (key: string, value: string) => void;
  unsetAmplitudeUserProperty: (key: string) => void;
  trackSegmentEvent: EventFunction;
  registerUserEventTracker: InitializeFunction<UserEventTrackerProps>;
  notifyRouteChange: InitializeFunction<RouteChangeTrackerProps>;
  trackDatadogAction: (name: string) => void;
}

export const enum AmplitudeEvents {
  // Page views
  // If adding a page view, also add it to the PAGE_VIEWS array below
  ViewedJoinFlow = 'Viewed Join Flow',
  ViewedJoinFlowRollout = 'Viewed Join Flow Rollout',
  ViewedAccessoriesScreen = 'Viewed Accessories Screen',
  ViewedOrderScreen = 'Viewed Order Screen',
  ViewedMembershipScreen = 'Viewed Select Membership Screen',
  ViewedFamilyMembershipScreen = 'Viewed Family Membership Screen',
  ViewedReceiptScreen = 'Viewed Receipt Screen',
  ViewedRetryScreen = 'Viewed Retry Screen',
  ViewedPasswordScreen = 'Viewed Join Flow PW Screen',

  // Landing page interactions
  ProductDetailsViewed = 'Product Details Viewed', // Only used in old app, not ecomm
  ClickedFamilyMembership = 'Clicked Family Membership',

  // Cart interactions
  AddedItemToCart = 'Added Item to Cart',
  RemovedItemFromCart = 'Removed Item from Cart',
  ViewedCart = 'Viewed Cart',
  CloseCart = 'Close Cart',

  // Accessories page interactions
  OpenProductDetailsModal = 'Product Details Opened Modal',
  CloseProductDetailsModal = 'Product Details Closed Modal',
  OpenWhoopYourWayModal = 'Open WYW Modal',
  OpenReviewsModal = 'Product Details Clicked Reviews Details',
  ModalBackButton = 'Modal Back Button',

  // Order page interactions
  ExpressCheckout = 'Express Checkout',

  StartedUserSection = 'Started User Section',
  StartedAddressSection = 'Started Address Section',
  StartedPaymentForm = 'Started Payment Form',
  CompletedUserSection = 'Completed User Section',
  CompletedAddressSection = 'Completed Address Section',
  CompletedShippingSection = 'Completed Shipping Section',
  PressedPlaceOrder = 'Pressed Place Order',
  PlacedOrder = 'Placed Order',
  JoinFlowError = 'Join Flow Error',
  OpenPromoCodeInput = 'Expanded Promo Code Section',
  EnteredPromoCode = 'Entered Promo Code',
  EmailSuggestionGiven = 'Email Suggestion Given',
  ClickedEdit = 'Clicked Edit',

  // Trial page interactions
  TrialsViewedAccountCreation = 'Trials - Viewed Account Creation',
  TrialsCreatedAccount = 'Trials - Created Account',
  TrialsViewedPaymentPage = 'Trials - Viewed Payment Page',
  TrialsClickedApplePay = 'Trials - Clicked Apple Pay',
  TrialsClickedGooglePay = 'Trials - Clicked Google Pay',
  TrialsClickedCreditCardButton = 'Trials - Clicked Credit Card Button',
  TrialsSubmittedCreditCard = 'Trials - Submitted Credit Card',
  TrialsClickedQrCameraButton = 'Trials - Clicked QR Camera Button',
  TrialsViewedStrapIdConfig = 'Trials - Viewed Strap ID Configuration',
  TrialsClickedActivateBand = 'Trials - Clicked Activate Band',
  TrialsActivatedStrapId = 'Trials - Activated Strap ID',
  TrialsViewedSuccessPage = 'Trials - Viewed Success Page',
  TrialsClickedDownloadApp = 'Trials - Clicked Download App',
  HeroCarousel = 'Join Flow Hero Carousel',

  // Gift Messaging
  GiftMessaging = 'Gift Messaging',

  // RAF Gift
  RAFGiftSignInPage = 'RAF Gift Sign In Page',

  //Other
  Scrolled = 'Scrolled',
  ClickedHeader = 'Clicked Header',
  OpenedFAQ = 'Opened FAQ',

  // Web Vitals
  WebVitals = 'Join Flow Web Vitals',

  // Country Selector
  JoinFlowCountrySelector = 'Join Flow Country Selector',
}

export const enum AmplitudeProperties {
  FamilyMembershipVisible = 'Family Membership Visible',
  FamilyCartUpsellClick = 'Cart - Family Membership Upsell',
  FamilyCartChangeSize = 'Cart - Change Family Size',
}

export const AmplitudeActions = {
  Viewed: 'viewed',
  Toggle: 'toggle',

  // Express Checkout
  ExpressActionOpen: 'Open',
  ExpressActionCancel: 'Cancel',
  ExpressActionAddressError: 'Address Error',
  ExpressActionPaymentError: 'Payment Error',
  ExpressActionStripeRefresh: 'Mismatched Stripe Accounts',
  ExpressActionSuccess: 'Success',

  // Country Selector
  CountrySelectorActionOpen: 'Opened Selector',
  CountrySelectorActionClose: 'Closed Selector',
  CountrySelectorActionChangeSite: 'Changed Site',

  // Hero Carousel
  HeroCarouselActionIncrement: 'Clicked Increment',
  HeroCarouselActionDecrement: 'Clicked Decrement',
  HeroCarouselActionHoverScroll: 'Hover Scroll',
  HeroCarouselActionClickedCta: 'Clicked CTA',

  // Family
  ClickMorePlanOptions: 'Click More Plan Options',

  //Join Flow Errors
  JoinFlowErrorActionCreateOrder: 'create_order',
  JoinFlowErrorActionSetShippingAddress: 'set_shipping_address',
  JoinFlowErrorActionGetReceipt: 'get_receipt_content_post_payment',
  JoinFlowErrorActionPrepareOrder: 'prepare_order_for_payment',
  JoinFlowErrorActionConfirmPayment: 'confirm_payment',
  JoinFlowErrorActionConfirmCardPayment: 'confirm_card_payment',
  JoinFlowErrorActionPlaceOrder: 'place_order',
} as const;

export const PAGE_VIEWS = [
  AmplitudeEvents.ViewedJoinFlow,
  AmplitudeEvents.ViewedMembershipScreen,
  AmplitudeEvents.ViewedAccessoriesScreen,
  AmplitudeEvents.ViewedOrderScreen,
];

export const enum SegmentEvents {
  RAFShareClick = 'Refer Friend- Invite Friend',
}
